import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';


import {
  SkinConfig,
  SkinningService,
} from './skinning.service';


export interface ConfigResponse {
  body: {
    env: string;
    vhost: string;
    logger: string;
    partner_uuid: string;
    saml: {
      samls: string[];
    };
    name: string;
    core_api: string;
    skin: string;
    magiclink: boolean;
    signup: boolean;
    s3_api: string;
    skin_detail: SkinConfig & {
      external_links?: {
        website_link: string;
        website_link_label: string;
        product_documentation: string;
        privacy: string;
        imprint: string;
        contact_form: string;
        release_notes: string;
      };
    };
    partner_api: string;
    partner_api_legacy: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private errorInterval?: number;

  private myApiEnv = '';
  private myVhost = '';
  private myLogger = '';
  private myPartnerUuid = '';
  private mySamls: any[] = [];
  private myCoreApi = '';
  private myPartnerName = '';
  private mySkin = '';
  private mySkinDetail?: SkinConfig & {
    external_links?: {
      website_link: string;
      website_link_label: string;
      product_documentation: string;
      privacy: string;
      imprint: string;
      contact_form: string;
      release_notes: string;
    };
  };
  private myMagicLink = false;
  private mySignup = false;
  private myS3Api = '';
  private myPartherApi = '';
  private myPartnerLegacyApi = '';

  /**
   * the raw response body from the endpoint
   */
  configRaw: any;

  closeActionMenus$: Subject<string | null> = new Subject();

  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
    private readonly actions$: ActionsSubject,
    private readonly skinningService: SkinningService,
  ) {
  }



  get ready$(): Observable<boolean> {
    return this.initialized.asObservable();
  }

  get samls(): any[] {
    return this.mySamls;
  }

  get vHost(): string {
    return this.myVhost;
  }

  get coreAPI(): string {
    return this.myCoreApi;
  }

  get skin(): string {
    return this.mySkin;
  }

  get partnerName(): string {
    return this.myPartnerName;
  }

  get partnerUuid(): string {
    return this.myPartnerUuid;
  }

  get environmentApi(): string {
    return this.myApiEnv;
  }

  get logger(): string {
    return this.myLogger;
  }

  get magiclink(): boolean {
    return this.myMagicLink;
  }

  get signup(): boolean {
    return this.mySignup;
  }

  get s3Api(): string {
    return this.myS3Api;
  }

  get partnerApi(): string {
    return this.myPartherApi;
  }

  get partnerLegacyApi(): string {
    return this.myPartnerLegacyApi;
  }

  get skinDetail() {
    return this.mySkinDetail;
  }

  get isGS(): boolean {
    return (
      this.myVhost.toLowerCase() === 'my.gridscale.io' ||
      this.myVhost.toLowerCase() === 'my.gridscale.dev'
    );
  }

  get isWhitelabel(): boolean {
    return this.myPartnerUuid === 'd8beadab-80a1-4e44-bcaf-000185ffde3f' /* special whitelabeldev uuid for testing! */ || !this.coreAPI.match(/\.gridscale\.(io|cloud|dev)$/);
  }

  get isLocalhost(): boolean {
    return window.location.hostname == 'localhost';
  }



  /**
   * Fetch the config via API
   * NOTE: when using in cloud-panel the HTTP interceptor will prefix the `environment.apiUrl` setting to the url,
   *       when using in partner-panel you need to pass this as `urlPrefix`
   */
  async loadApiConfig(urlPrefix = '') {
    return new Promise<void>((_resolve, _reject) => {
      this.http.get<ConfigResponse>(urlPrefix.replace(/\/$/, '') + '/v2/config').subscribe(
        (res) => {
          if (this.errorInterval) {
            window.clearInterval(this.errorInterval);
          }

          const apiConfig = res.body;
          this.myApiEnv = apiConfig.env;
          this.myVhost = apiConfig.vhost;
          this.myPartnerName = apiConfig.name;
          this.myLogger = apiConfig.logger;
          this.myPartnerUuid = apiConfig.partner_uuid;
          this.mySamls = apiConfig.saml ? apiConfig.saml.samls : [];
          this.myCoreApi = apiConfig.core_api;
          this.mySkin = apiConfig.skin;
          this.myMagicLink = apiConfig.magiclink;
          this.mySignup = apiConfig.signup;
          this.myS3Api = apiConfig.s3_api;
          this.myPartherApi = apiConfig.partner_api;
          this.myPartnerLegacyApi = apiConfig.partner_api_legacy;

          if (apiConfig.skin_detail) {
            this.mySkinDetail = apiConfig.skin_detail;

            this.skinningService.overrideSkinVars(apiConfig.skin_detail);
          } else {
            this.skinningService.overrideSkinVars(
              this.skinningService.getDefaultValues()
            );
          }

          // set favicon
          const iconEl = document.querySelector('link[rel=icon]');
          if (iconEl) {
            if (apiConfig.skin_detail?.favicon_data) {
              iconEl.setAttribute('href', apiConfig.skin_detail.favicon_data);
              const matches =
                apiConfig.skin_detail.favicon_data.match(/\.([a-z]+)$/i);
              if (matches) {
                if (matches[1].toLowerCase() !== 'png') {
                  iconEl.setAttribute('type', 'image/x-icon');
                }
              }
            } else {
              iconEl.setAttribute(
                'href',
                '/igstatic/skins/logos/generic/favicon.ico'
              );
            }
          }


          this.configRaw = { ...res.body };



          this.logConfigToConsole();
          this.initialized.next(true);
          _resolve();

        },
        (err) => {
          _reject(err);
        }
      );
    });
  }





  private logConfigToConsole() {
    if (
      this.myApiEnv == 'development' ||
      window.location.hostname == 'localhost'
    ) {
      console.group('%cCurrent Config', 'color: #ff766a;font-size: 20px;');
      console.log('%cEnviroment: ' + this.myApiEnv, 'color: #6bb7c4;');
      console.log('%cAPI Used: ' + this.myCoreApi, 'color: #6bb7c4;');
      console.groupEnd();
    }
  }
}
