import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as defaultVars from './skinning_default_variables.json';

export type BASE_COLOR_OPTION = '$primaryColor' | '$secondaryColor' | '$accentColor' | '$primaryTextColor' | '$primaryDarkColor';

export const skinConfigObj = {
  primary_color: "",
  primary_text_color: "",
  secondary_color: "",
  secondary_text_color: "",
  skin_name: "",
  invert_header: false,
  logo_data: "",
  logo_width: "",
  favicon_data: "",
  background_data: "",
  background_size: "",
  skin_font_family: "",
  skin_font_family_headline: "",
  accent_color: "",
  header_background_color: "",
  invert_header_items: false,
  header_form_color: "",
  header_form_background_color: "",
  menu_link_color: "",
  headlines_color: "",
  button_color: "",
  invert_buttons: false,
  icons_color: "",
  pricebox_color: "",
  pricebox_color_background: "",
  invert_pricebox_button: "",
  rounded_icon_background_active: "",
  rounded_icon_color_active: "",
  rounded_icon_background_inactive: "",
  rounded_icon_color_inactive: "",
  cta_button_background_color: "",
  cta_button_color: "",
  login_icon_headline_color: "",
  login_button_color: ""
};
export interface SkinConfig extends Partial<typeof skinConfigObj> {
  header_background_color?: BASE_COLOR_OPTION;
  header_form_color?: BASE_COLOR_OPTION;
  header_form_background_color?: BASE_COLOR_OPTION;
  menu_link_color?: BASE_COLOR_OPTION;
  headlines_color?: BASE_COLOR_OPTION;
  button_color?: BASE_COLOR_OPTION;
  pricebox_color?: BASE_COLOR_OPTION;
  pricebox_color_background?: BASE_COLOR_OPTION;
  rounded_icon_background_active?: BASE_COLOR_OPTION;
  rounded_icon_color_active?: BASE_COLOR_OPTION;
  rounded_icon_background_inactive?: BASE_COLOR_OPTION;
  rounded_icon_color_inactive?: BASE_COLOR_OPTION;
}


@Injectable({
  providedIn: 'root'
})
export class SkinningService {
  resolvedVarValues: Record<string, string | number> = {};

  /**
   * returns the default values
   * @param noFinallyResolveFor An array with variable names that should *not* be resolved to the finally value, but stop if a plain variable name is found
   */
  getDefaultValues(noFinallyResolveFor?: string[]): SkinConfig {
    this.resolvedVarValues = {};

    const skinConfig: SkinConfig = {};
    const possibleKeys = Object.keys(skinConfigObj);

    _.forEach(defaultVars, (defaultValue, varName) => {
      const apiName = varName.replace(/(.)([A-Z])/g, (x, y, z) => y + '_' + z.toLowerCase());

      if (possibleKeys.indexOf(apiName) >= 0) {
        _.set(skinConfig, [apiName], this.resolveValue(defaultValue, defaultVars, _.toInteger(noFinallyResolveFor?.indexOf(apiName)) >= 0));
      }
    });

    return skinConfig;
  }

  /**
   * overrides the skin vars during runtime
   * e.g. when dynamic skin config is loaded from API
   */
  overrideSkinVars(skinConfig: SkinConfig, targetElement = document.documentElement) {
    this.resolvedVarValues = {};

    const vars: Record<string, string | null | undefined> = {};
    _.forEach(skinConfig, (value: string | boolean, varName: string) => {

      if (value) {
        if (varName === 'logo_data') {
          value = 'url(' + value + ')';
        }

        if (varName === 'logo_width') {
          value += 'px';
        }

        if (varName === 'background_data' && (value as string).match(/[a-z]+:/)) {
          value = 'url(' + value + ')';
        }

        // there is a typo in the backend
        if (varName === 'buttons_color' && skinConfig.button_color === undefined) {
          varName = 'button_color';
        }
      }
      // if (varName === 'invert_headline' || varName === 'invert_header_items') {
      //   varName = 'invert_header';
      //   value = !!value ? "true" : "false";
      // }
      vars[varName.replace(/_[a-z]/g, m => m[1].toUpperCase())] = value === undefined || value === null ? (value as string) : String(value);
    });


    _.forEach(defaultVars, (defaultValue, varName) => {
      if (vars[varName] !== undefined && vars[varName] !== null && vars[varName] !== '') {
        // if we got provided with that variable we just set it up to the next var
        this.setStyleProperty(targetElement, varName, vars[varName]!, { ...defaultVars, ...vars });
        return true;
      } else {
        // default value is a simple type, jiust set it and up to the next var
        this.setStyleProperty(targetElement, varName, defaultValue as string, { ...defaultVars, ...vars });
        return true;
      }
    });
  }

  /**
   * converts a hex color notation to rgb values
   */
  hexToRGB(hex: string) {
    if (hex.indexOf('#') === 0) {
      hex = hex.substring(1);
    }
    const r = parseInt(hex.length === 3 ? hex[0].repeat(2) : hex[0] + hex[1], 16);
    const g = parseInt(hex.length === 3 ? hex[1].repeat(2) : hex[2] + hex[3], 16);
    const b = parseInt(hex.length === 3 ? hex[2].repeat(2) : hex[4] + hex[5], 16);

    return {
      r, g, b
    };
  }

  /**
   * converts a hex color notation to HSL notation
   * based on https://css-tricks.com/converting-color-spaces-in-javascript/
   */
  hexToHSL(hex: string) {
    // Convert hex to RGB first
    const rgb = this.hexToRGB(hex);

    // Then to HSL
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;
    let h = 0,
      s = 0,
      l = 0;

    if (delta == 0)
      h = 0;
    else if (cmax == r)
      h = ((g - b) / delta) % 6;
    else if (cmax == g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
      h, s, l
    };
  }

  /**
   * converts HSL color notation to hex notation
   * found on https://css-tricks.com/converting-color-spaces-in-javascript/
   */
  HSLToHex(h: number, s: number, l: number) {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c / 2;
    let r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    let r2 = Math.round((r + m) * 255).toString(16);
    let g2 = Math.round((g + m) * 255).toString(16);
    let b2 = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r2.length == 1)
      r2 = "0" + r2;
    if (g2.length == 1)
      g2 = "0" + g2;
    if (b2.length == 1)
      b2 = "0" + b2;

    return "#" + r2 + g2 + b2;
  }




  /**
   * resolve a variable value/function etc. to the plain value
   * @param varValue the value
   * @param otherVars all available variables
   * @param stopAtPlainValue true if resolving should be stopped at first plain value (no function)
   * @returns
   */
  private resolveValue(varValue: any, otherVars: any, stopAtPlainValue = false): any {
    // check if the varValue is a variable itself and resolve
    const igMatches = typeof varValue === 'string' ? varValue.match(/^\$(ig[A-Z][a-zA-Z0-9_]+)$/) : false;
    if (typeof varValue === 'string' && igMatches) {
      // ingrid variables will be converted to var()
      return 'var(--' + this.getDashedVarName(igMatches[1]) + ')';

    } else if (typeof varValue === 'string' && varValue.match(/^\$([a-zA-Z0-9_]+)$/) && stopAtPlainValue) {
      return varValue;

    } else if (typeof varValue === 'string' && varValue.match(/^\$([a-zA-Z0-9_]+)$/)) {
      const matches = varValue.match(/^\$([a-zA-Z0-9_]+)$/);
      if (typeof (this.resolvedVarValues[matches![1]]) === 'string' || typeof (this.resolvedVarValues[matches![1]]) === 'number') {
        return this.resolvedVarValues[matches![1]];
      } else if (typeof otherVars[matches![1]] !== 'undefined') {
        return this.resolveValue(otherVars[matches![1]], otherVars, stopAtPlainValue);
      }

      console?.warn('skinning.service could not resolve variable', matches![1]);
      return undefined;
    } else if (varValue['sass-function'] !== undefined) {
      // uha we need to execute a sass function
      // rgba
      if (varValue['sass-function'] === 'rgba') {
        const inputColor = this.resolveValue(varValue['sass-function-args'][0], otherVars);
        if (inputColor) {
          const match = (inputColor as string).match(/^#([a-f0-9]){3,6}$/i);
          if (match) {
            const rgb = this.hexToRGB(inputColor);
            let output = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b;
            if (varValue['sass-function-args'][1]) {
              output += ', ' + varValue['sass-function-args'][1];
            }
            output += ')';
            return output;

          } else {
            console?.warn('skinning.service implementation of sass-rgba only supports hexadecimal values as parameter 0', varValue);
          }
        }

      } else if (varValue['sass-function'] === 'scale-color') {
        // scale-color
        const inputColor = this.resolveValue(varValue['sass-function-args'][0], otherVars);

        if (varValue['sass-function-args'][1] && typeof varValue['sass-function-args'][1] === 'string') {
          const match = varValue['sass-function-args'][1].match(/^\$lightness:\s?(-?[0-9]+)%$/);
          if (match) {
            // we need HSL notation first
            const hsl = this.hexToHSL(inputColor);

            if (hsl) {
              const scale = parseInt(match[1], 10);
              hsl.l = Math.round((hsl.l + (scale > 0 ? 100 - hsl.l : hsl.l) * (scale / 100)) * 100) / 100;
              // and back to hex and set
              return this.HSLToHex(hsl.h, hsl.s, hsl.l);
            }
          } else {
            console?.warn('skinning.service: unsupported parameter for sass function "scale-color"', varValue['sass-function-args'][1], 'only lightness is supported atm');
          }

        } else {
          console?.warn('skinning.service: unsupported parameter for sass function "scale-color"', varValue['sass-function-args'][1]);
        }

      } else {
        console?.warn('skinning.service sass function ', varValue['sass-function'], ' not implemented');
      }
    } else if (varValue['if'] !== undefined && varValue['then'] !== undefined && varValue['else'] !== undefined) {
      // condition
      const ifVar = varValue['if'][0];
      const ifVal = this.resolveValue(ifVar, otherVars);



      if (ifVal === varValue['if'][1]) {
        return this.resolveValue(varValue['then'], otherVars, stopAtPlainValue);
      } else {
        return this.resolveValue(varValue['else'], otherVars, stopAtPlainValue);
      }
    }

    // if nothing handled the value before it's maybe a simple string that can be retrned
    return varValue;

  }

  private setStyleProperty(targetElement: HTMLElement, varName: string, varValue: string | number, otherVars: Record<string, any>) {
    const val = this.resolveValue(varValue, otherVars);
    if (val !== undefined) {

      this.resolvedVarValues[varName] = val;
      targetElement.style.setProperty("--" + this.getDashedVarName(varName), val);
    }
  }

  private getDashedVarName(varName: string) {
    // nameDashed function copied from the `export_types.js` script used for generating the ...props.js file during ingrid build
    return varName
      .replace("$", "")
      .replace(/([A-Z])/g, _match => {
        if (typeof _match === "string") {
          return "-" + _match.toLowerCase();
        }
        return _match;
      });
  }
}
