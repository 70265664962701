{
  "$schema": "./skinning_default_variables.schema.json",
  "primaryColor": "#15425f",
  "primaryTextColor": "#FFFFFF",
  "primaryColorDarker20": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$primaryColor",
      "$lightness: -20%"
    ]
  },
  "primaryColorRgba10": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.1
    ]
  },
  "primaryColorRgba20": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.2
    ]
  },
  "primaryColorRgba30": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.3
    ]
  },
  "primaryColorRgba40": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.4
    ]
  },
  "primaryColorRgba50": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.5
    ]
  },
  "primaryColorRgba60": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.6
    ]
  },
  "primaryColorRgba70": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.7
    ]
  },
  "primaryColorRgba80": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.8
    ]
  },
  "primaryColorRgba90": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$primaryColor",
      0.9
    ]
  },
  "primaryColorHover": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$primaryColor",
      "$lightness: -20%"
    ]
  },
  "primaryDarkColor": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$primaryColor",
      "$lightness: -50%"
    ]
  },
  "primaryDarkerColor": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$primaryColor",
      "$lightness: -80%"
    ]
  },
  "secondaryColor": "#2e83a0",
  "accentColor": "#4caf50",
  "headlinesColor": "$secondaryColor",
  "headlineLoginColor": "$headlinesColor",
  "roundedIconColorActive": "#FFFFFF",
  "roundedIconBackgroundActive": "$primaryColor",
  "roundedIconColorInactive": "$primaryColor",
  "roundedIconBackgroundInactive": "$igColorLightGreyBackground",
  "inputFormBorder": "$igColorMediumGreyOutline",
  "inputFormGlow": "#c4e2e7",
  "buttonColor": "$secondaryColor",
  "buttonColorDisabled": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$buttonColor",
      0.5
    ]
  },
  "buttonHoverColor": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$buttonColor",
      "$lightness: -20%"
    ]
  },
  "ctaButtonBackgroundColor": "$accentColor",
  "ctaButtonBackgroundColorHover": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$ctaButtonBackgroundColor",
      "$lightness: -20%"
    ]
  },
  "ctaButtonBackgroundColorDisabled": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$ctaButtonBackgroundColor",
      0.5
    ]
  },
  "ctaButtonColor": "#FFFFFF",
  "headerBackgroundColor": "$primaryColor",
  "headerFormColor": "#FFFFFF",
  "headerFormBackgroundColor": "$primaryColor",
  "headerFormPlaceholderColor": {
    "sass-function": "rgba",
    "sass-function-args": [
      "$headerFormColor",
      0.4
    ]
  },
  "tableHeaderBackgroundColor": "$headerBackgroundColor",
  "tableHeaderBorderColor": "$headerFormPlaceholderColor",
  "tableHeaderFontColor": "$headerFormColor",
  "skinFontFamily": "Roboto",
  "skinFontFamilyHeadline": "Roboto",
  "logoData": "transparent",
  "logoWidth": "200px",
  "backgroundData": "$primaryColor",
  "backgroundSize": "cover",
  "colorLightgrey": "#999999",
  "colorFiligranGrey": "#eeeeee",
  "colorGreen": "$igColorSuccessGreen",
  "colorGreenDark": "$igColorSuccessGreenHover",
  "colorRed": "#e50000",
  "colorOrange": "#e58800",
  "priceboxColorBackground": "$primaryColor",
  "priceboxColor": "#FFFFFF",
  "iconsColor": "$secondaryColor",
  "iconsDarkerColor": {
    "sass-function": "scale-color",
    "sass-function-args": [
      "$iconsColor",
      "$lightness: -50%"
    ]
  },
  "linkColor": "$iconsColor",
  "linkColorHover": "$iconsDarkerColor",
  "loginIconHeadlineColor": "$secondaryColor",
  "loginButtonColor": "$secondaryColor",
  "textSecondaryColor": "#666666"
}
